import {MoneyCollectOutlined} from '@ant-design/icons';
import {APP_PREFIX_PATH} from 'configs/AppConfig';


const menuApp = [
/*    {
        key: 'dashboards-app',
        path: `${APP_PREFIX_PATH}/dashboards-app`,
        title: 'sidenav.dashboard',
        icon: DashboardOutlined,
        permission: "",
        image: '/img/others/menu-1.png',
        breadcrumb: false,
        submenu: [],
    },*/

    {
        key: 'salary',
        path: `${APP_PREFIX_PATH}/list`,
        title: 'app.salary',
        icon: MoneyCollectOutlined,
        breadcrumb: false,
        permission: "",

        submenu: [
 /*           {
                key: 'salary-structure',
                path: `${APP_PREFIX_PATH}/salary-structure`,
                title: 'app.salary-structure',
                icon: TableOutlined,
                breadcrumb: false,
                permission: "",
                submenu: [],
            },
            {
                key: 'paycheck',
                path: `${APP_PREFIX_PATH}/paycheck`,
                title: 'app.paycheck',
                icon: FileProtectOutlined,
                breadcrumb: false,
                permission: "",

                submenu: [],
            },*/
            {
                key: 'salary-summaries',
                path: `${APP_PREFIX_PATH}/report/list`,
                title: 'app.salary_summaries',
                icon: MoneyCollectOutlined,
                breadcrumb: false,
                permission: "",
                submenu: [],
            },
        ],
    },


/*    {
        key: 'report',
        path: `${APP_PREFIX_PATH}/report`,
        title: 'app.report',
        icon: LineChartOutlined,
        breadcrumb: false,
        submenu: [
            {
                key: 'kpi-spreadsheet',
                path: `${APP_PREFIX_PATH}/report/kpi-spreadsheet`,
                title: 'app.report.kpi_spreadsheet',
                icon: CalculatorOutlined,
                breadcrumb: false,
                permission: "",

                submenu: [],
            },
            // {
            //     key: 'kpi-spreadsheet-department',
            //     path: `${APP_PREFIX_PATH}/report/kpi-spreadsheet-department`,
            //     title: 'app.report.kpi_spreadsheet_department',
            //     icon: CalculatorOutlined,
            //     breadcrumb: false,
            //     permission: [HRM_ROLE_TAG],
            //
            //     submenu: [],
            // },
            {
                key: 'kpi-spreadsheet-room',
                path: `${APP_PREFIX_PATH}/report/kpi-spreadsheet-room`,
                title: 'app.report.kpi_spreadsheet_room',
                icon: CalculatorOutlined,
                breadcrumb: false,
                permission: [HRM_ROLE_TAG,"manager"],
                submenu: [],
            },
        ],
    },*/
];

const navigationConfig = [
    ...menuApp,
];

export default navigationConfig;
